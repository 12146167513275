import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { ListItem } from '@latitude/list';
import { Strong, Text } from '@latitude/text';
import { ALIGN } from '@latitude/core/utils/constants';

function AreYouEligibleSection({ id, heading }) {
  return (
    <Section id={id} heading={heading}>
      <Text align={ALIGN.CENTER}>
        <Strong>To be eligible for a Personal Loan you must:</Strong>
      </Text>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        <TickedList>
          <ListItem>Be 18 years or over</ListItem>
          <ListItem>
            Be able to demonstrate a good credit history for the last 5 years
          </ListItem>
          <ListItem>Be a permanent Australian resident</ListItem>
        </TickedList>
        <TickedList>
          <ListItem>Be free from bankruptcy for the last 7 years</ListItem>
          <ListItem>
            Be currently employed and earning at least $25,000 gross per annum
          </ListItem>
        </TickedList>
      </div>
    </Section>
  );
}

const TickedList = styled.ul`
  && {
    flex: 0 0 45%;
    min-width: 300px;
    list-style-image: url(${require('./images/tick-simple.svg').default});
  }
  && li {
    padding-bottom: 10px;
  }
`;

export default AreYouEligibleSection;

import React from 'react';

import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { COLOR, FONT_WEIGHT, MARGIN } from '@latitude/core/utils/constants';

const WHY_LATITUDE = {
  icon: './images/why-latitude1.webp',
  title: 'Why Latitude?',
  jsx: (
    <Text color={COLOR.WHITE}>
      We've been helping Aussies manage their finances for close to a century.
      Trusted by 2.1 millions customers today, we're ready to back your journey,
      wherever it leads.
    </Text>
  )
};

// eslint-disable-next-line arrow-body-style
function WhyLatitudeSection({ id }) {
  return (
    <Section id={id} css={{ backgroundColor: '#5E47B9' }}>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'center',
          padding: '20px 0'
        }}
      >
        <div
          css={{
            flexBasis: '345px',
            maxWidth: '345px',
            width: '100%'
          }}
        >
          <Text
            fontSize="24px"
            fontWeight={FONT_WEIGHT.BOLD}
            color={COLOR.WHITE}
            marginBottom={MARGIN.M8}
          >
            {WHY_LATITUDE.title}
          </Text>
          {WHY_LATITUDE.jsx}
        </div>
        <div
          css={{
            flexBasis: '345px',
            maxWidth: '345px',
            width: '100%'
          }}
        >
          <img
            css={{ objectFit: 'cover', width: '100%' }}
            src={require(`${WHY_LATITUDE.icon}`).default}
            alt={WHY_LATITUDE.title}
          />
        </div>
      </div>
    </Section>
  );
}

export default WhyLatitudeSection;

/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FeaturesSlider } from '@latitude/features-slider';
import PLData from '@/data/pages/personal-loan-to-go.json';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import HowToApply from '@latitude/how-to-apply';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import queryString from 'query-string';
import { Box } from '@latitude/box';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import LoanRates from '@/components/LoanRates/LoanRates';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { Heading4 } from '@/components/Heading/Heading';
import { List, ListItem } from '@/components/List';
import { Strong } from '@/components/Text/Bold';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  SOFT_QUOTE_LOANS_AU_URL
} from '../../utils/constants';
import { parseContent } from '../../utils/helpers';
import footerData from '@/data/json/footer.json';
import HowToApplyListLoansToGo from '../HowToApplyLoansToGo/HowToApplyListLoansToGo';
import LfsHeader from '../Header/LfsHeader';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '../lab-components/HeroBanner';

// Shared eligibility criteria content
export const PLEligibilityCriteria = props => {
  const {
    isSecuredLoanOnly,
    isUnsecuredLoanOnly,
    customEligibilityCriteria
  } = props;
  const ListHeading = ({ children }) => {
    return (
      <Strong
        css={`
          display: block;
          margin: ${MARGIN.M8} 0;
        `}
      >
        {children}
      </Strong>
    );
  };

  // work out best rate section title
  let bestRateTitle = '';
  if (isSecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestSecuredRateTitle;
  } else if (isUnsecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestUnsecuredRateTitle;
  } else {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestRateTitle;
  }

  // work out eligibility criteria list data
  let eligibilityCriteria = [];
  if (
    Array.isArray(customEligibilityCriteria) &&
    customEligibilityCriteria.length
  ) {
    eligibilityCriteria = [...customEligibilityCriteria];
  } else {
    eligibilityCriteria = [...PLData.content.eligibilityCriteria];
  }

  // This is to ensure that personal loan page always scroll to top
  // get started - soft quote - exit timeout - no to stop scroll point at get started button
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Heading4
        color={COLOR.BLACK}
        marginBottom={MARGIN.M16}
        isResponsive={false}
      >
        Eligibility
      </Heading4>

      <ListHeading>{PLData.content.eligibilityCriteriaTitle}</ListHeading>
      <List>
        {eligibilityCriteria.map((item, index) => {
          return <ListItem key={index}>{parseContent(item) || ''}</ListItem>;
        })}
      </List>
    </React.Fragment>
  );
};

const PersonalLoanToGoPageContent = props => {
  let queryParams;
  if (typeof window !== 'undefined') {
    queryParams = queryString.parse(location.search);
  }

  if (queryParams && queryParams.partnername) {
    typeof window !== 'undefined' &&
      sessionStorage.setItem('partnername', queryParams.partnername);
  } else {
    typeof window !== 'undefined' && sessionStorage.removeItem('partnername');
  }

  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  const featureSliderData = [
    {
      heading: 'Loans2Go Benefits with a Latitude Personal Loan',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
  }, []);

  return (
    <Layout
      location={props.location}
      metaDesc={PLData.metaDesc}
      title={PLData.title}
      canonical={props.location.href}
      customFooter={footerData}
      noHeader
      noBreadcrumb
      noIndex
    >
      <Box
        css={`
          .top-menu-items,
          .logon-button-wrapper,
          .search-button,
          .top-menu-items__overlay {
            display: none !important;
          }
        `}
      >
        <LfsHeader {...props} />
      </Box>
      <main
        className="navigation-spacer"
        css={`
          div[class*='SiteLinksstyles__Outer-'],
          .top-menu-items {
            display: none !important;
          }
        `}
      >
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />

          <HeroBranded
            css={`
              background-image: linear-gradient(180deg, #0A49A8, #47B6B8);
              > div > div:first-child {
                @media (max-width: ${BREAKPOINT.LG}) {
                  width: 47% !important;
                }
                @media (max-width: ${BREAKPOINT.MD}) {
                  width: 100% !important;
                }
              }
              > div > div.HeroContent {
                @media (min-width: ${BREAKPOINT.MD}) {
                  width: 85% !important;
                  margin-right: 25px;
                }
                a.trackcustomanalytics {
                  width: auto;
                }
              }
            `}
            imageContent={
              <Box
                css={`
                  height: 250px;
                  width: auto;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    width: 300px;
                    margin: 80px auto;
                  }

                  @media (min-width: ${BREAKPOINT.MD}) {
                    display: flex;
                    height: 300px;
                  }

                  @media (min-width: ${BREAKPOINT.LG}) {
                    height: 395px;
                  }
                  .gatsby-image-wrapper img {
                    height: auto;
                    top: 115px;
                    @media (max-width: ${BREAKPOINT.MD}) {
                      top: 0;
                    }
                  }
                `}
              >
                <StaticImage 
                  src="../../images/hero/loans-to-go-logo.webp"
                  alt="Personal Loans"
                  width={417}
                  quality={90}
                  placeholder="blurred"
                />
              </Box>
            }
            title={
              <>
              <div
                css={`
                  color:#fff
                  `}
              >
                Latitude Personal Loans with Loans2Go
              </div>
                <br />
              </>
            }
            text={
              <>
              <div
                css={`
                color:#fff
                `}
              >
                Your Loans2Go Benefits Code has been registered. Apply today and
                receive $0 Establishment Fee along with a $250 eGift Mastercard
                for approved and settled applications. Check your interest rate
                in 2 minutes with no impact to your credit score.
              </div>
                <br />
              </>
            }
            buttonProps={{
              href: applyUrl,
              children: 'Get Rate Estimate',
              trackId: 'hero-get-started',
              width: '220px'
            }}
            trackEventData={{
              category: 'cta',
              action: 'quote-link'
            }}
            />

          <div
            className="d-none d-lg-block"
            css={`
              position: relative;
              z-index: 2;
            `}
          >
            <StickyNavigation
              items={PLData.nav}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              trackProductId={['PLAULF-WEB']}
              offsetElem="[data-sticky-navigation-offset]"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              {...state?.inPageNavData?.[0]}
            />
          </div>
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="about-this-offer"
            className="why-choose bg-f8f8f8"
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
          />

          <EstimateRateWidgetSection
            id="check-your-rate"
            purpose="purpose"
            parentOnChangeEvent={() => {
              setSoftQuoteHref(redirectUrl());
            }}
          />

          <div>
            <LoanRates
              isBranded
              rateBoxType="variableSymLEF"
              rateBoxType2="fixedSymLEF"
              fees={PLData.content.aboutTheLoan}
              extraTCs="true"
              determineRateLink
              css={`.loan-type{display:none;`}
              rates = {PLData.content.RatesData}
            />
          </div>

          <div id="fees-charges">
            <FeesAndCharges data={PLData.content.feesAndChargesLEF1} />
          </div>

          <HowToApply
            id="how-to-apply"
            box1={<HowToApplyListLoansToGo variant="sym" />}
            box2={<PLEligibilityCriteria />}
          />

          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loanLoanToGo.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            additionalPaymentsDisclaimerLEF
            {...state?.importantInfoData?.[0]}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export default PersonalLoanToGoPageContent;

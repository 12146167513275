import React from 'react';

import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Promo } from '@latitude/promo';
import { Link } from '@latitude/link';

const COLOUR_PALE_BLUE = '#E5F6FF';
const CTA_LABEL = 'Get your personalised rate';

// eslint-disable-next-line arrow-body-style
function NextStepsSection({
  id,
  ctaLinkHref,
  ctaButtonLabel = CTA_LABEL,
  ctaTrackingLocation = 'personal-loan-calculator-next-step'
}) {
  return (
    <Section
      id={id}
      css={`
        background-color: ${COLOUR_PALE_BLUE};
        && h3 {
          font-size: 32px;
        }
      `}
    >
      <Promo
        title="Ready to take the next step with a Latitude loan?"
        description={
          <Text>
            Find out your personalised rate in just minutes &mdash; no impact on
            your credit score.
          </Text>
        }
        frameImage={
          <img
            src={require('./images/next-step.webp').default}
            alt=""
            css={{ borderRadius: '14px' }}
          />
        }
        verticalAlignContent
        frameAlignment="left"
        frameBorderColor="transparent"
        ctaButtons={
          <Link
            button={BUTTON_STYLE.PRIMARY}
            href={ctaLinkHref}
            target="_blank"
            trackId="next-step"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              label: ctaButtonLabel,
              location: ctaTrackingLocation
            }}
            trackProductId={['PLAULF-WEB']}
            css={{ width: 'fit-content', padding: '10pt 20pt' }}
          >
            {ctaButtonLabel}
          </Link>
        }
      />
    </Section>
  );
}

export default NextStepsSection;

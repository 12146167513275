/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';

import { FeaturesSlider } from '@latitude/features-slider';
import PLData from '@/data/pages/personal-loans.json';
import { StickyNavigation } from '@latitude/sticky-navigation';
import SvgInline from '@latitude/svg-inline';

import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import CalculatorTile from '@/components/PersonalLoanSoftQuote/CalculatorTile';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import queryString from 'query-string';
import { Box } from '@latitude/box';
import Faq from '@/components/Faq/Faq';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { Heading4 } from '@/components/Heading/Heading';
import { List, ListItem } from '@/components/List';
import { Strong } from '@/components/Text/Bold';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import {
  BREAKPOINT,
  COLOR,
  MARGIN,
  PL_APPLY_CTA_TRACK_ID,
  SOFT_QUOTE_LOANS_AU_URL
} from '../../utils/constants';
import { parseContent } from '../../utils/helpers';
import { PageContext } from '@/context/PageContext';

import footerData from '@/data/json/footer-personal-loans.json';
import heroImage from '../../images/hero/hero-personal-loans-1.webp';
import Button from '../Button/Button';
import HeroBanner from '@/components/lab-components/HeroBanner';
import Section from '@latitude/section';
import PersonalLoanCalculator from '../PersonalLoanCalculator2/PersonalLoanCalculator';
import LifeCantWaitSection from './LifeCantWaitSection';
import WhyLatitudeSection from './WhyLatitudeSection';
import ApplyingIsSimpleSection from './ApplyingIsSimpleSection';
import ProductComparisonSection from './ProductComparisonSection';
import NextStepsSection from './NextStepsSection';

/* This page is a duplicate of /personal-loan/ with amended copy because Google has flagged our current paid media links to the AU Personal Loans page -- please don't delete
Please refer to CEPW-1518 for more information
*/

const COLOUR_PALE_BLUE = '#E5F6FF';
const CTA_BUTTON_LABEL = 'Get my rate estimate';
const CTA_TRACKING_LABEL = 'Get rate estimate';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }
  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

// Shared eligibility criteria content
export const PLEligibilityCriteria = props => {
  const {
    isSecuredLoanOnly,
    isUnsecuredLoanOnly,
    customEligibilityCriteria
  } = props;
  const ListHeading = ({ children }) => {
    return (
      <Strong
        css={`
          display: block;
          margin: ${MARGIN.M8} 0;
        `}
      >
        {children}
      </Strong>
    );
  };

  // work out best rate section title
  let bestRateTitle = '';
  if (isSecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestSecuredRateTitle;
  } else if (isUnsecuredLoanOnly) {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestUnsecuredRateTitle;
  } else {
    bestRateTitle = PLData.content.eligibilityCriteriaForBestRateTitle;
  }

  // work out eligibility criteria list data
  let eligibilityCriteria = [];
  if (
    Array.isArray(customEligibilityCriteria) &&
    customEligibilityCriteria.length
  ) {
    eligibilityCriteria = [...customEligibilityCriteria];
  } else {
    eligibilityCriteria = [...PLData.content.eligibilityCriteria];
  }

  return (
    <React.Fragment>
      <Heading4
        color={COLOR.BLACK}
        marginBottom={MARGIN.M16}
        isResponsive={false}
      >
        Eligibility
      </Heading4>

      <ListHeading>{PLData.content.eligibilityCriteriaTitle}</ListHeading>
      <List>
        {eligibilityCriteria.map((item, index) => {
          return <ListItem key={index}>{parseContent(item) || ''}</ListItem>;
        })}
      </List>
    </React.Fragment>
  );
};

const PersonalLoanPageContent = props => {
  let queryParams;
  if (typeof window !== 'undefined') {
    queryParams = queryString.parse(location.search);
  }

  if (queryParams && queryParams.partnername) {
    typeof window !== 'undefined' &&
      sessionStorage.setItem('partnername', queryParams.partnername);
  } else {
    typeof window !== 'undefined' && sessionStorage.removeItem('partnername');
  }

  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderJson = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  return (
    <Layout
      location={props.location}
      metaDesc={PLData.metaDesc}
      title={PLData.title}
      canonical={props.location.href}
      customFooter={footerData}
      noIndex
      hasStickyNav
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title={"Personal loans for life's big moments"}
              subTitle={
                <>
                  Do that renovation. Take that holiday. Buy that car. Take
                  control of your finances. Apply online and get a response in
                  60 seconds.
                </>
              }
              pageImage={heroImage}
              pageImageAlt="Personal loan"
            >
              <div className="text-white row">
                <div className="text-left col-6 col-md-5">
                  <HomeButton
                    href={applyUrl}
                    trackId="personal-loans-button"
                    trackEventData={{
                      label: CTA_TRACKING_LABEL,
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLAULF-WEB']}
                    className="button--primary"
                  >
                    {CTA_BUTTON_LABEL}
                  </HomeButton>
                </div>
                <div className="p-0 text-center col-1">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="text-left col-5 col-md-6 padding-right HeroContent__insettext">
                  Find out your estimated interest rate in 2 minutes
                </div>
              </div>
            </PageHeaderSection>
          )}

          <div
            className="d-none d-lg-block"
            css={`
              position: relative;
              z-index: 2;
            `}
          >
            <StickyNavigation
              items={PLData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText={CTA_BUTTON_LABEL}
              offsetElem="[data-sticky-navigation-offset]"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              {...state?.inPageNavData?.[0]}
            />
          </div>

          <FeaturesSlider
            css={`
              && ul {
                text-align: left;
              }
              && h4 {
                text-align: left;
                font-size: 32px;
                line-height: 36px;
              }
            `}
            key={
              (state?.featureSliderData?.[0] || featureSliderJson[0]).heading
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderJson[0]).heading
            }
            id="why-us"
            className="why-choose bg-f8f8f8"
            subheading={
              (state?.featureSliderData?.[0] || featureSliderJson[0])
                .description
            }
            data={
              (state?.featureSliderData?.[0] || featureSliderJson[0])
                .featureCards
            }
          />

          <Section
            id="repayment-calculator"
            heading="Get an estimate of your repayments"
            css={`
              background-color: ${COLOUR_PALE_BLUE};
              && h2.text-center {
                text-align: left !important;
                font-size: 32px;
                line-height: 36px;
              }
            `}
          >
            <PersonalLoanCalculator
              loans1
              ctaButtonLabel={CTA_BUTTON_LABEL}
              applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
            />
          </Section>
          <ProductComparisonSection
            id="compare-products"
            heading="Compare our fixed and variable personal loan options to find the right fit for you"
          />

          <CalculatorTile />
          <ApplyingIsSimpleSection
            id="applying-is-simple"
            heading="Applying for a Latitude Personal Loan is simple"
            showEligible={true}
          />
          <LifeCantWaitSection
            id="life-cant-wait"
            heading="When life can't wait Latitude is here to help"
          />
          <NextStepsSection
            ctaLinkHref={softQuoteHref}
            ctaButtonLabel={CTA_BUTTON_LABEL}
            ctaTrackingLocation="personal-loans-page-next-step"
          />
          <WhyLatitudeSection personalLoan={true} />

          <Faq
            css={`
              && h2.faq__heading {
                text-align: left !important;
                font-size: 32px;
                line-height: 36px;
              }
            `}
            data={PLData.content.faq}
            {...state?.faqData?.[0]}
          />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export default PersonalLoanPageContent;
